import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import PDF from '../../../assets/icons/PDF.png'
import {
  getAllCR,
  getAllFiche,
  getAllPassation,
  getAllEvenement,
  getAllNote,
  getAllAvancement,
  updateCr,
  updatefiche,
  updateEnementEtPassation,
} from '../../../helpers/rapport/rapport'
import PageNotFound from '../../../components/404/PageNotFound'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { useToasts } from 'react-toast-notifications'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
import axios from 'axios'
//page des comptes rendu et fiche de visite
const Rapport = () => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const [bool, setBool] = useState(false)
  const [cr, setCr] = useState([])
  const [dataCr, setDataCr] = useState([])
  const [fiche, setFiche] = useState([])
  const [passation, setPassation] = useState([])
  const [evenements, setEvenements] = useState([])
  const [avancement, setAvancement] = useState([])
  const [note, setNote] = useState([])
  const [wait, setWait] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [type, setType] = useState(1)
  const [item, setItem] = useState(
    localStorage.getItem('typeRapport')
      ? localStorage.getItem('typeRapport')
      : 'cr'
  )
  const [crFilter, setCrFilter] = useState('tous')
  useEffect(() => {
    setWait(true)
    getAllCR(infoChantier.planning, infoUser.token)
      .then((e) => {
        if (e.name == 'AxiosError') {
          setCr([])
        } else {
          setDataCr(e.data)
          setCr(e.data)
        }
        getAllFiche(infoChantier.planning, infoUser.token).then((e) => {
          if (e.name == 'AxiosError') {
            setFiche([])
          } else {
            setFiche(e.data)
          }
        })
        getAllPassation(infoChantier.planning, infoUser.token).then((e) => {
          if (e.name == 'AxiosError') {
            setPassation([])
          } else {
            setPassation(e.data)
          }
        })
        getAllEvenement(infoChantier.planning, infoUser.token).then((e) => {
          if (e.name == 'AxiosError') {
            setEvenements([])
          } else {
            setEvenements(e.data)
          }
        })
        getAllNote(infoChantier.planning, infoUser.token).then((e) => {
          if (e.name == 'AxiosError') {
            setNote([])
          } else {
            setNote(e.data)
          }
        })
        getAllAvancement(infoChantier.planning, infoUser.token).then((e) => {
          if (e.name == 'AxiosError') {
            //setNote([])
          } else {
            setAvancement(e.data)
          }
        })
      })
      .finally((res) => {
        setWait(false)
      })
  }, [bool])
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  //fonction pour le nav bar scroll horizontal
  const rights = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const lefts = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="rapport" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild" style={{ paddingTop: '12px' }}>
            <div
              class="page-title-box"
              style={{
                padding: '16px 5px',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                margin: '5px 10px',
                boxShadow:
                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                marginBottom: '15px',
              }}>
              <div class="float-end" style={{ padding: '10px 30px' }}>
                {' '}
                {(infoUser?.user?.role?.roleType == 'admin' ||
                  infoUser?.user?.role?.roleType == 'opc' ||
                  infoUser?.user?.role?.roleType == 'moex') && (
                  <>
                    <button
                      data-bs-toggle="dropdown"
                      className="btn"
                      style={{ backgroundColor: '#4F46E5', color: '#F8FAFC' }}>
                      + Nouveau
                    </button>
                  </>
                )}
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(`/Rapport_NewCR?brouillon=false`)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Comptes rendus
                    </a>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_NewFiche')
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Rapport des visites
                    </a>
                  </li>
                 {/*  <li
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Fiche_de_passation')
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Fiche de passation
                    </a>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Evenement_de_chantier')
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Événements de chantier
                    </a>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/note_de_chantier')
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Note de chantier
                    </a>
                  </li> */}
                  <li
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_avancement')
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Rapport d'avancement
                    </a>
                  </li>
                </ul>
              </div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  / Rapports
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/accueil')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Rapports
                </span>
              </span>
            </div>
            <div className="row container-fluid">
              <div className="col-lg-3">
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '24px 16px ',
                    boxShadow:
                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                    borderRadius: '8px',
                    border: ' 1px solid #E2E8F0',
                    marginTop: '10px',
                    width: '100%',
                    height: '75vh',
                  }}>
                  <span
                    style={
                      item == 'cr'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('cr')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'cr' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Comptes rendus
                    </span>
                  </span>


                  
                  {/* <span
                    style={
                      item == 'fiche'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('fiche')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'fiche' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Rapports des visites
                    </span>
                  </span> */}




                  <span
                    style={
                      infoUser?.user?.formule === 'opclte'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px 10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            fontStyle: 'italic',
                          }
                        : item === 'fiche'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px 10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px 10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      if (infoUser?.user?.formule !== 'opclte') {
                        e.preventDefault();
                        setItem('fiche');
                      }
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'fiche' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </span>
                    <span
                      style={
                        infoUser?.user?.formule === 'opclte'
                        ? {
                          marginLeft: '12px', // 12px
                          color: '#8a929e', // 8a929e
                          fontSize: '14px', // 14px
                          }
                        : {
                          marginLeft: '12px',
                          }
                      }
                    >
                      Rapports des visites
                      {infoUser?.user?.formule === 'opclte' ? ' - PRO' : ''}
                    </span>
                  </span>


                  {/* <span
                    style={
                      item == 'passation'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('passation')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'passation' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Fiche de passation
                    </span>
                  </span>
                  <span
                    style={
                      item == 'evenement'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('evenement')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'evenement' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Événements de chantier
                    </span>
                  </span>
                  <span
                    style={
                      item == 'note'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('note')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'note' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Note de chantier
                    </span>
                  </span> */}
                  <span
                    style={
                      infoUser?.user?.formule === 'opclte'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px 10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            fontStyle: 'italic',
                          }
                        : item === 'avancement'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px 10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px 10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      if (infoUser?.user?.formule !== 'opclte') {
                        e.preventDefault();
                        setItem('avancement');
                      }
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'avancement' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </span>
                    <span
                      style={
                        infoUser?.user?.formule === 'opclte'
                        ? {
                          marginLeft: '12px', // 12px
                          color: '#8a929e', // 8a929e
                          fontSize: '14px', // 14px
                          }
                        : {
                          marginLeft: '12px',
                          }
                      }
                    >
                      Rapport d'avancement
                      {infoUser?.user?.formule === 'opclte' ? ' - PRO' : ''}
                    </span>
                  </span>
                  {/* <span
                    style={
                      item == 'fermer'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('fermer')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'fermer' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Bon à fermer (Bientôt)
                    </span>
                  </span>
                  <span
                    style={
                      item == 'reception'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('reception')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'reception' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Réception rapports (Bientôt)
                    </span>
                  </span>
                  <span
                    style={
                      item == 'rapport'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('rapport')
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'rapport' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}>
                      Rapport mensuels (Bientôt)
                    </span>
                  </span> */}
                </div>
              </div>
              <div
                className="col-lg-9"
                style={{
                  marginTop: '10px',
                  height: '72vh',
                  overflowY: 'scroll',
                }}>
                {/* Liste des compte rendu cr*/}
                {item == 'cr' && (
                  <>
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#64748B"
                          style={{ width: '24px', height: '24px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <span>
                        <input
                          type="text"
                          id=""
                          style={{
                            width: '80%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            marginLeft: '10px',
                            height: '100%',
                          }}
                          placeholder="Recherche"
                          onChange={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            const newData = searchOptimizedData(
                              ev.target.value,
                              cr
                            )
                            setCr(newData)
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <span>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#000000',
                          }}>
                          Comptes rendus{' '}
                        </span>
                        <span
                          style={{
                            padding: '4px 8px',
                            backgroundColor: ' #E2E8F0',
                            borderRadius: '24px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          {cr?.length ? cr.length : 0}
                        </span>
                      </span>
                      <span>
                        {/**Boutton filtrage colonne */}
                        <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            marginRight: '10px',
                          }}
                          id="dropdownMenuButton5"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Filtrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#6366F1"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton5"
                          onClick={(e) => {}}>
                          <li
                            style={
                              crFilter == 'tous'
                                ? { backgroundColor: '#E2E8F0' }
                                : {}
                            }
                            onClick={(ev) => {
                              ev.preventDefault()
                              setCrFilter('tous')
                              setCr(dataCr)
                            }}>
                            <a href="#" class="dropdown-item">
                              Tous
                            </a>
                          </li>
                          <li
                            style={
                              crFilter == 'diffuse'
                                ? { backgroundColor: '#E2E8F0' }
                                : {}
                            }
                            onClick={(ev) => {
                              ev.preventDefault()
                              setCrFilter('diffuse')
                              setCr(
                                dataCr.filter((el) => el.etatCR == 'envoye')
                              )
                            }}>
                            <a href="#" class="dropdown-item">
                              Diffusés
                            </a>
                          </li>
                          <li
                            style={
                              crFilter == 'valide'
                                ? { backgroundColor: '#E2E8F0' }
                                : {}
                            }
                            onClick={(ev) => {
                              ev.preventDefault()
                              setCrFilter('valide')
                              setCr(
                                dataCr.filter(
                                  (el) =>
                                    (el.etatCR == 'valide') |
                                    (el.etatCR == 'attenteEnvoie')
                                )
                              )
                            }}>
                            <a href="#" class="dropdown-item">
                              Validés
                            </a>
                          </li>
                          <li
                            style={
                              crFilter == 'brouillon'
                                ? { backgroundColor: '#E2E8F0' }
                                : {}
                            }
                            onClick={(ev) => {
                              ev.preventDefault()
                              setCrFilter('brouillon')
                              setCr(
                                dataCr.filter((el) => el.etatCR == 'brouillon')
                              )
                            }}>
                            <a href="#" class="dropdown-item">
                              Brouillons
                            </a>
                          </li>
                        </ul>
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                      }}>
                      {cr &&
                        cr.map((e) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: '10px',
                                  borderRadius: '8px',
                                  padding: '24px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  border: '1px solid #E2E8F0',
                                  backgroundColor:
                                    e.etatCR == 'brouillon'
                                      ? 'lightgrey'
                                      : '#FFFFFF',
                                  boxShadow:
                                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                                }}>
                                <span style={{ width: '70%' }}>
                                  <span>
                                    <img
                                      src={PDF}
                                      alt=""
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      color: '#475569',
                                    }}>
                                    {e.nomFichier ? e.nomFichier : '---'}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    width: '20%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#475569',
                                  }}>
                                  {e.dateCR ? dateFr(e.dateCR) : '---'}
                                </span>
                                <span
                                  className="dropdown-toggle"
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                  data-bs-toggle="dropdown">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#64748B"
                                    style={{ width: '24px', height: '24px' }}>
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    />
                                  </svg>
                                </span>
                                <ul class="dropdown-menu">
                                  {e.etatCR == 'brouillon' && (
                                    <>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          onClick={(ev) => {
                                            ev.preventDefault()
                                            console.log(e)
                                            const data = {
                                              dateCR: e.dateCR,
                                              prochainReunion:
                                                e.prochainReunion,
                                              typeCr: e.typeCr,
                                              generalites: e.generalites,
                                              administratif: e.administratif,
                                              _id: e._id,
                                              oldCr: e,
                                            }
                                            navigate(
                                              `/Rapport_NewCR?brouillon=true&data=${JSON.stringify(
                                                data
                                              )}`
                                            )
                                          }}>
                                          Modifier
                                        </a>
                                      </li>
                                    </>
                                  )}
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/file/${e.fileToken}`}
                                      target="_blank">
                                      Voir
                                    </a>
                                  </li>
                                  <li
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      setWait(true)
                                      fetch(
                                        `${process.env.REACT_APP_URL}/api/v1/comptesrendu/file/${e.fileToken}`
                                      )
                                        .then((response) => response.blob())
                                        .then((blob) => {
                                          const url =
                                            window.URL.createObjectURL(blob)
                                          const a = document.createElement('a')
                                          a.href = url
                                          a.download = `${e.nomFichier}`
                                          document.body.appendChild(a)
                                          a.click()
                                          document.body.removeChild(a)
                                          window.URL.revokeObjectURL(url)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'Erreur lors du téléchargement du fichier:',
                                            error
                                          )
                                        })
                                        .finally((res) => {
                                          setWait(false)
                                        })
                                    }}>
                                    <span class="dropdown-item">
                                      Télécharger
                                    </span>
                                  </li>
                                  <li
                                    onClick={(el) => {
                                      updateCr(
                                        e._id,
                                        infoUser.token,
                                        infoChantier.planning
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.name == 'AxiosError') {
                                          addToast(
                                            res.response?.data?.message
                                              ? res.response.data.message
                                              : "Une erreur s'est produit",
                                            {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            }
                                          )
                                        } else {
                                          addToast(
                                            'Le compte rendu a été supprimé avec succès.',
                                            {
                                              appearance: 'success',
                                              autoDismiss: true,
                                            }
                                          )
                                        }
                                        setBool(!bool)
                                      })
                                    }}>
                                    <a class="dropdown-item" href="#">
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
                {item == 'fiche' && (
                  <>
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#64748B"
                          style={{ width: '24px', height: '24px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <span>
                        <input
                          type="text"
                          id=""
                          style={{
                            width: '80%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            marginLeft: '10px',
                            height: '100%',
                          }}
                          placeholder="Recherche"
                          onChange={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            const newData = searchOptimizedData(
                              ev.target.value,
                              fiche
                            )
                            setFiche(newData)
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <span>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#000000',
                          }}>
                          Rapports des visites{' '}
                        </span>
                        <span
                          style={{
                            padding: '4px 8px',
                            backgroundColor: ' #E2E8F0',
                            borderRadius: '24px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          {fiche?.length ? fiche.length : 0}
                        </span>
                      </span>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {/**Boutton filtrage colonne */}
                        {infoChantier.observationXls && (
                          <>
                            <button
                              className="btn"
                              style={{
                                backgroundColor: '#2DD4BF',
                                color: '#F8FAFC',
                                marginRight: '20px',
                              }}
                              onClick={(ev) => {
                                ev.preventDefault()
                                setWait(true)
                                axios
                                  .post(
                                    `${process.env.REACT_APP_URL}/api/v1/fiches/excelObservation`,
                                    { planning: infoChantier.planning },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${infoUser.token}`,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res)
                                    /* const blob = new Blob([res.data]); 
                             console.log(blob);
                              const url = window.URL.createObjectURL(blob);
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'fiche.xlsx'); // Définissez le nom du fichier à télécharger
                              document.body.appendChild(link);
                              link.click();
                              window.URL.revokeObjectURL(url); */
                                    addToast(
                                      'La fiche de visite a été envoyée avec succès.',
                                      {
                                        appearance: 'success',
                                        autoDismiss: true,
                                      }
                                    )
                                  })
                                  .catch((err) => {
                                    console.log(err)
                                    addToast("Une erreur s'est produit", {
                                      appearance: 'error',
                                      autoDismiss: true,
                                    })
                                  })
                                  .finally((res) => {
                                    setWait(false)
                                  })
                              }}>
                              Export excel
                            </button>
                          </>
                        )}
                        {/* <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            marginRight: '10px',
                          }}
                          id="dropdownMenuButton6"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Filtrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#6366F1"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton5"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          <li>
                            <a href="#" class="dropdown-item">
                              Tous
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Diffusés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Validés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Brouillons
                            </a>
                          </li>
                        </ul> */}
                      </span>
                    </div>
                    <div
                      className="container-fluid"
                      style={{ display: 'flex'}}>
                      <button
                        className="btn"
                        style={{
                          width: '10px',
                          height: '52px',
                          backgroundColor: 'rgba(226, 232, 240, 0.6)',
                          borderRadius: '0px',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px',
                          borderTop: '1px solid #CBD5E1',
                          borderBottom: '1px solid #CBD5E1',
                          borderLeft: '1px solid #CBD5E1',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          lefts()
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          style={{
                            width: '20px',
                            height: '20px',
                          }}>
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div
                        className="wrapper"
                        >
                        <div
                          className="item"
                          onClick={(ev) => {
                            ev.preventDefault()
                            setType(1)
                          }}>
                          <span
                            style={
                              type == 1
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 24px',
                                    borderRadius: '8px',
                                    marginLeft: '10px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            Visites
                          </span>
                        </div>
                        <div
                          className="item"
                          onClick={(ev) => {
                            ev.preventDefault()
                            setType(2)
                          }}>
                          <span
                            style={
                              type == 2
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 24px',
                                    borderRadius: '8px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            Réserves
                          </span>
                        </div>
                        <div
                          className="item"
                          onClick={(ev) => {
                            ev.preventDefault()
                            setType(3)
                          }}>
                          <span
                            style={
                              type == 3
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 24px',
                                    borderRadius: '8px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            Réception de supports
                          </span>
                        </div>
                        <div
                          className="item"
                          onClick={(ev) => {
                            ev.preventDefault()
                            setType(4)
                          }}>
                          <span
                            style={
                              type == 4
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 24px',
                                    borderRadius: '8px',
                                    marginRight: '10px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            Avancements
                          </span>
                        </div>
                        <div
                          className="item"
                          onClick={(ev) => {
                            ev.preventDefault()
                            setType(5)
                          }}
                          >
                          <span
                            style={
                              type == 5
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 24px',
                                    borderRadius: '8px',
                                    marginRight: '10px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            Bons à fermer
                          </span>
                        </div>
                      </div>
                      <button
                        className="btn"
                        style={{
                          width: '10px',
                          height: '52px',
                          backgroundColor: 'rgba(226, 232, 240, 0.6)',
                          borderRadius: '0px',
                          borderTopRightRadius: '8px',
                          borderBottomRightRadius: '8px',
                          textAlign: 'center',
                          position: 'relative',
                          borderTop: '1px solid #CBD5E1',
                          borderBottom: '1px solid #CBD5E1',
                          borderRight: '1px solid #CBD5E1',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          rights()
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          style={{
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            left: '0',
                            top: '17',
                          }}>
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                      }}>
                      {fiche &&
                        fiche.map((e) => {
                          if (e.typeFiche == type || e.typeFiche == undefined) {
                            return (
                              <>
                                <div
                                  style={{
                                    marginTop: '10px',
                                    borderRadius: '8px',
                                    padding: '24px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    border: '1px solid #E2E8F0',
                                    backgroundColor: '#FFFFFF',
                                    boxShadow:
                                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                                  }}>
                                  <span style={{ width: '70%' }}>
                                    <span>
                                      <img
                                        src={PDF}
                                        alt=""
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}
                                      />
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        color: '#475569',
                                      }}>
                                      {e.nomDocument ? e.nomDocument : '---'}
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      width: '20%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      color: '#475569',
                                    }}>
                                    {e.dateExport
                                      ? dateFr(e.dateExport)
                                      : '---'}
                                  </span>
                                  <span
                                    className="dropdown-toggle"
                                    style={{
                                      width: '10%',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                    data-bs-toggle="dropdown">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="#64748B"
                                      style={{ width: '24px', height: '24px' }}>
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                      />
                                    </svg>
                                  </span>
                                  <ul class="dropdown-menu">
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href={`${process.env.REACT_APP_URL}/api/v1/visites/file/${e.fileToken}`}
                                        target="_blank">
                                        Voir
                                      </a>
                                    </li>
                                    <li
                                      onClick={(ev) => {
                                        ev.preventDefault()
                                        setWait(true)
                                        fetch(
                                          `${process.env.REACT_APP_URL}/api/v1/visites/file/${e.fileToken}`
                                        )
                                          .then((response) => response.blob())
                                          .then((blob) => {
                                            const url =
                                              window.URL.createObjectURL(blob)
                                            const a =
                                              document.createElement('a')
                                            a.href = url
                                            a.download = `${e.nomDocument}`
                                            document.body.appendChild(a)
                                            a.click()
                                            document.body.removeChild(a)
                                            window.URL.revokeObjectURL(url)
                                          })
                                          .catch((error) => {
                                            console.error(
                                              'Erreur lors du téléchargement du fichier:',
                                              error
                                            )
                                          })
                                          .finally((res) => {
                                            setWait(false)
                                          })
                                      }}>
                                      <span class="dropdown-item">
                                        Télécharger
                                      </span>
                                    </li>
                                    <li
                                      onClick={(el) => {
                                        updatefiche(
                                          e._id,
                                          infoUser.token,
                                          infoChantier.planning
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.name == 'AxiosError') {
                                            addToast(
                                              res.response?.data?.message
                                                ? res.response.data.message
                                                : "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          } else {
                                            addToast(
                                              'La fiche de visite a été supprimée avec succès.',
                                              {
                                                appearance: 'success',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                          setBool(!bool)
                                        })
                                      }}>
                                      <a class="dropdown-item" href="#">
                                        Supprimer
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </>
                )}
                {item == 'passation' && (
                  <>
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#64748B"
                          style={{ width: '24px', height: '24px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <span>
                        <input
                          type="text"
                          id=""
                          style={{
                            width: '80%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            marginLeft: '10px',
                            height: '100%',
                          }}
                          placeholder="Recherche"
                          onChange={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            const newData = searchOptimizedData(
                              ev.target.value,
                              passation
                            )
                            setPassation(newData)
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <span>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#000000',
                          }}>
                          Fiche de passation{' '}
                        </span>
                        <span
                          style={{
                            padding: '4px 8px',
                            backgroundColor: ' #E2E8F0',
                            borderRadius: '24px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          {passation?.length ? passation.length : 0}
                        </span>
                      </span>
                      <span>
                        {/**Boutton filtrage colonne */}
                        {/* <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            marginRight: '10px',
                          }}
                          id="dropdownMenuButton6"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Filtrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#6366F1"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton5"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          <li>
                            <a href="#" class="dropdown-item">
                              Tous
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Diffusés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Validés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Brouillons
                            </a>
                          </li>
                        </ul> */}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                      }}>
                      {passation &&
                        passation.map((e) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: '10px',
                                  borderRadius: '8px',
                                  padding: '24px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  border: '1px solid #E2E8F0',
                                  backgroundColor: '#FFFFFF',
                                  boxShadow:
                                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                                }}>
                                <span style={{ width: '70%' }}>
                                  <span>
                                    <img
                                      src={PDF}
                                      alt=""
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      color: '#475569',
                                    }}>
                                    {e.nomFichier ? e.nomFichier : '---'}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    width: '20%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#475569',
                                  }}>
                                  {e.dateDeCreation
                                    ? dateFr(e.dateDeCreation)
                                    : '---'}
                                </span>
                                <span
                                  className="dropdown-toggle"
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                  data-bs-toggle="dropdown">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#64748B"
                                    style={{ width: '24px', height: '24px' }}>
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    />
                                  </svg>
                                </span>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href={`${process.env.REACT_APP_URL}/api/v1/fiches/file/0/${e.fileToken}`}
                                      target="_blank">
                                      Voir
                                    </a>
                                  </li>
                                  <li
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      setWait(true)
                                      fetch(
                                        `${process.env.REACT_APP_URL}/api/v1/fiches/file/0/${e.fileToken}`
                                      )
                                        .then((response) => response.blob())
                                        .then((blob) => {
                                          const url =
                                            window.URL.createObjectURL(blob)
                                          const a = document.createElement('a')
                                          a.href = url
                                          a.download = `${e.nomFichier}`
                                          document.body.appendChild(a)
                                          a.click()
                                          document.body.removeChild(a)
                                          window.URL.revokeObjectURL(url)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'Erreur lors du téléchargement du fichier:',
                                            error
                                          )
                                          addToast(
                                            "Une erreur s'est produit.",
                                            {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            }
                                          )
                                        })
                                        .finally((res) => {
                                          setWait(false)
                                        })
                                    }}>
                                    <span class="dropdown-item">
                                      Télécharger
                                    </span>
                                  </li>
                                  <li
                                    onClick={(el) => {
                                      updateEnementEtPassation(
                                        e._id,
                                        infoUser.token,
                                        infoChantier.planning
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.name == 'AxiosError') {
                                          addToast(
                                            res.response?.data?.message
                                              ? res.response.data.message
                                              : "Une erreur s'est produit",
                                            {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            }
                                          )
                                        } else {
                                          addToast(
                                            'La fiche de passation a été supprimée avec succès.',
                                            {
                                              appearance: 'success',
                                              autoDismiss: true,
                                            }
                                          )
                                        }
                                        setBool(!bool)
                                      })
                                    }}>
                                    <a class="dropdown-item" href="#">
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
                {item == 'evenement' && (
                  <>
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#64748B"
                          style={{ width: '24px', height: '24px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <span>
                        <input
                          type="text"
                          id=""
                          style={{
                            width: '80%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            marginLeft: '10px',
                            height: '100%',
                          }}
                          placeholder="Recherche"
                          onChange={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            const newData = searchOptimizedData(
                              ev.target.value,
                              evenements
                            )
                            setEvenements(newData)
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <span>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#000000',
                          }}>
                          Événements de chantier{' '}
                        </span>
                        <span
                          style={{
                            padding: '4px 8px',
                            backgroundColor: ' #E2E8F0',
                            borderRadius: '24px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          {evenements?.length ? evenements.length : 0}
                        </span>
                      </span>
                      <span>
                        {/**Boutton filtrage colonne */}
                        {/*   <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            marginRight: '10px',
                          }}
                          id="dropdownMenuButton6"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Filtrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#6366F1"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton5"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          <li>
                            <a href="#" class="dropdown-item">
                              Tous
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Diffusés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Validés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Brouillons
                            </a>
                          </li>
                        </ul> */}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                      }}>
                      {evenements &&
                        evenements.map((e) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: '10px',
                                  borderRadius: '8px',
                                  padding: '24px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  border: '1px solid #E2E8F0',
                                  backgroundColor: '#FFFFFF',
                                  boxShadow:
                                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                                }}>
                                <span style={{ width: '70%' }}>
                                  <span>
                                    <img
                                      src={PDF}
                                      alt=""
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      color: '#475569',
                                    }}>
                                    {e.nomFichier ? e.nomFichier : '---'}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    width: '20%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#475569',
                                  }}>
                                  {e.dateDeCreation
                                    ? dateFr(e.dateDeCreation)
                                    : '---'}
                                </span>
                                <span
                                  className="dropdown-toggle"
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                  data-bs-toggle="dropdown">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#64748B"
                                    style={{ width: '24px', height: '24px' }}>
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    />
                                  </svg>
                                </span>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href={`${process.env.REACT_APP_URL}/api/v1/fiches/file/1/${e.fileToken}`}
                                      target="_blank">
                                      Voir
                                    </a>
                                  </li>
                                  <li
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      setWait(true)
                                      fetch(
                                        `${process.env.REACT_APP_URL}/api/v1/fiches/file/1/${e.fileToken}`
                                      )
                                        .then((response) => response.blob())
                                        .then((blob) => {
                                          const url =
                                            window.URL.createObjectURL(blob)
                                          const a = document.createElement('a')
                                          a.href = url
                                          a.download = `${e.nomFichier}`
                                          document.body.appendChild(a)
                                          a.click()
                                          document.body.removeChild(a)
                                          window.URL.revokeObjectURL(url)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'Erreur lors du téléchargement du fichier:',
                                            error
                                          )
                                        })
                                        .finally((res) => {
                                          setWait(false)
                                        })
                                    }}>
                                    <span class="dropdown-item">
                                      Télécharger
                                    </span>
                                  </li>
                                  <li
                                    onClick={(el) => {
                                      updateEnementEtPassation(
                                        e._id,
                                        infoUser.token,
                                        infoChantier.planning
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.name == 'AxiosError') {
                                          addToast(
                                            res.response?.data?.message
                                              ? res.response.data.message
                                              : "Une erreur s'est produit",
                                            {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            }
                                          )
                                        } else {
                                          addToast(
                                            "La fiche d'événements de chantier a été supprimée avec succès.",
                                            {
                                              appearance: 'success',
                                              autoDismiss: true,
                                            }
                                          )
                                        }
                                        setBool(!bool)
                                      })
                                    }}>
                                    <a class="dropdown-item" href="#">
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
                {item == 'note' && (
                  <>
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#64748B"
                          style={{ width: '24px', height: '24px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <span>
                        <input
                          type="text"
                          id=""
                          style={{
                            width: '80%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            marginLeft: '10px',
                            height: '100%',
                          }}
                          placeholder="Recherche"
                          onChange={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            const newData = searchOptimizedData(
                              ev.target.value,
                              note
                            )
                            setNote(newData)
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <span>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#000000',
                          }}>
                          Note de chantier{' '}
                        </span>
                        <span
                          style={{
                            padding: '4px 8px',
                            backgroundColor: ' #E2E8F0',
                            borderRadius: '24px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          {note?.length ? note.length : 0}
                        </span>
                      </span>
                      <span>
                        {/**Boutton filtrage colonne */}
                        {/* <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            marginRight: '10px',
                          }}
                          id="dropdownMenuButton6"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Filtrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#6366F1"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton5"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          <li>
                            <a href="#" class="dropdown-item">
                              Tous
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Diffusés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Validés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Brouillons
                            </a>
                          </li>
                        </ul> */}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                      }}>
                      {note &&
                        note.map((e) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: '10px',
                                  borderRadius: '8px',
                                  padding: '24px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  border: '1px solid #E2E8F0',
                                  backgroundColor: '#FFFFFF',
                                  boxShadow:
                                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                                }}>
                                <span style={{ width: '70%' }}>
                                  <span>
                                    <img
                                      src={PDF}
                                      alt=""
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      color: '#475569',
                                    }}>
                                    {e.nomFichier ? e.nomFichier : '---'}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    width: '20%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#475569',
                                  }}>
                                  {e.dateDeCreation
                                    ? dateFr(e.dateDeCreation)
                                    : '---'}
                                </span>
                                <span
                                  className="dropdown-toggle"
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                  data-bs-toggle="dropdown">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#64748B"
                                    style={{ width: '24px', height: '24px' }}>
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    />
                                  </svg>
                                </span>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href={`${process.env.REACT_APP_URL}/api/v1/fiches/file/2/${e.fileToken}`}
                                      target="_blank">
                                      Voir
                                    </a>
                                  </li>
                                  <li
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      setWait(true)
                                      fetch(
                                        `${process.env.REACT_APP_URL}/api/v1/fiches/file/2/${e.fileToken}`
                                      )
                                        .then((response) => response.blob())
                                        .then((blob) => {
                                          const url =
                                            window.URL.createObjectURL(blob)
                                          const a = document.createElement('a')
                                          a.href = url
                                          a.download = `${e.nomFichier}`
                                          document.body.appendChild(a)
                                          a.click()
                                          document.body.removeChild(a)
                                          window.URL.revokeObjectURL(url)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'Erreur lors du téléchargement du fichier:',
                                            error
                                          )
                                        })
                                        .finally((res) => {
                                          setWait(false)
                                        })
                                    }}>
                                    <span class="dropdown-item">
                                      Télécharger
                                    </span>
                                  </li>
                                  <li
                                    onClick={(el) => {
                                      updateEnementEtPassation(
                                        e._id,
                                        infoUser.token,
                                        infoChantier.planning
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.name == 'AxiosError') {
                                          addToast(
                                            res.response?.data?.message
                                              ? res.response.data.message
                                              : "Une erreur s'est produit",
                                            {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            }
                                          )
                                        } else {
                                          addToast(
                                            'La fiche du note de chantier a été supprimée avec succès.',
                                            {
                                              appearance: 'success',
                                              autoDismiss: true,
                                            }
                                          )
                                        }
                                        setBool(!bool)
                                      })
                                    }}>
                                    <a class="dropdown-item" href="#">
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
                {item == 'avancement' && (
                  <>
                    <div
                      style={{
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        borderRadius: '8px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#64748B"
                          style={{ width: '24px', height: '24px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <span>
                        <input
                          type="text"
                          id=""
                          style={{
                            width: '80%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            marginLeft: '10px',
                            height: '100%',
                          }}
                          placeholder="Recherche"
                          onChange={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            const newData = searchOptimizedData(
                              ev.target.value,
                              avancement
                            )
                            setAvancement(newData)
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <span>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#000000',
                          }}>
                          Avancement de chantier{' '}
                        </span>
                        <span
                          style={{
                            padding: '4px 8px',
                            backgroundColor: ' #E2E8F0',
                            borderRadius: '24px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}>
                          {avancement?.length ? avancement.length : 0}
                        </span>
                      </span>
                      <span>
                        {/**Boutton filtrage colonne */}
                        {/* <button
                          style={{
                            border: '1px solid #CBD5E1',
                            borderRadius: '8px',
                            padding: '8px',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            marginRight: '10px',
                          }}
                          id="dropdownMenuButton6"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Filtrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#6366F1"
                            style={{ width: '20px', height: '20px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton5"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          <li>
                            <a href="#" class="dropdown-item">
                              Tous
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Diffusés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Validés
                            </a>
                          </li>
                          <li>
                            <a href="#" class="dropdown-item">
                              Brouillons
                            </a>
                          </li>
                        </ul> */}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '8px 0px 10px 10px',
                        width: '100%',
                      }}>
                      {avancement &&
                        avancement.map((e) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: '10px',
                                  borderRadius: '8px',
                                  padding: '24px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  border: '1px solid #E2E8F0',
                                  backgroundColor: '#FFFFFF',
                                  boxShadow:
                                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                                }}>
                                <span style={{ width: '70%' }}>
                                  <span>
                                    <img
                                      src={PDF}
                                      alt=""
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      color: '#475569',
                                    }}>
                                    {e.nomFichier ? e.nomFichier : '---'}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    width: '20%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#475569',
                                  }}>
                                  {e.dateDeCreation
                                    ? dateFr(e.dateDeCreation)
                                    : '---'}
                                </span>
                                <span
                                  className="dropdown-toggle"
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                  data-bs-toggle="dropdown">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#64748B"
                                    style={{ width: '24px', height: '24px' }}>
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    />
                                  </svg>
                                </span>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href={`${process.env.REACT_APP_URL}/api/v1/fiches/file/3/${e.fileToken}`}
                                      target="_blank">
                                      Voir
                                    </a>
                                  </li>
                                  <li
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      setWait(true)
                                      fetch(
                                        `${process.env.REACT_APP_URL}/api/v1/fiches/file/3/${e.fileToken}`
                                      )
                                        .then((response) => response.blob())
                                        .then((blob) => {
                                          const url =
                                            window.URL.createObjectURL(blob)
                                          const a = document.createElement('a')
                                          a.href = url
                                          a.download = `${e.nomFichier}`
                                          document.body.appendChild(a)
                                          a.click()
                                          document.body.removeChild(a)
                                          window.URL.revokeObjectURL(url)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'Erreur lors du téléchargement du fichier:',
                                            error
                                          )
                                        })
                                        .finally((res) => {
                                          setWait(false)
                                        })
                                    }}>
                                    <span class="dropdown-item">
                                      Télécharger
                                    </span>
                                  </li>
                                  <li
                                    onClick={(el) => {
                                      updateEnementEtPassation(
                                        e._id,
                                        infoUser.token,
                                        infoChantier.planning
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.name == 'AxiosError') {
                                          addToast(
                                            res.response?.data?.message
                                              ? res.response.data.message
                                              : "Une erreur s'est produit",
                                            {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            }
                                          )
                                        } else {
                                          addToast(
                                            "Rapport d'avancement supprimée avec succès.",
                                            {
                                              appearance: 'success',
                                              autoDismiss: true,
                                            }
                                          )
                                        }
                                        setBool(!bool)
                                      })
                                    }}>
                                    <a class="dropdown-item" href="#">
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* loader */}
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default Rapport
